export const ROUTES_ZIM = {
  PURCHASE_ORDER: {
    LIST: '/purchase-orders',
    LIST_SHORT_URL: '/po',
    DETAIL: '/purchase-order/:poNumber',
    DETAIL_SHORTURL: '/po/:poNumber',
  },
  PROFILE: '/profile',
  LOG_EXPLORER: '/log-explorer',
  STOCK_TRACKER: '/stock-tracker',
  STOCK_EXPLORER: '/stock-explorer',
  DISCREPANCY_EXPLORER: '/discrepancy-explorer',
  LOCATIONS_V3: '/locations-v3',
  SUPPLIERS: '/suppliers',
  USERS: '/users',
}
