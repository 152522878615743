import { Timestamp } from 'firebase/firestore'
import mapValues from 'lodash/mapValues'

export const firestoreDatesConverter = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toFirestore: (entry: any) => entry,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fromFirestore: (snapshot: any): any => {
    const data = snapshot.data()
    return mapValuesDeep(data)
  },
}

const mapValuesDeep = function (value: unknown): unknown {
  if (Array.isArray(value)) {
    return value.map(mapValuesDeep)
  }

  if (typeof value === 'object') {
    return mapValues(value, (v: unknown) => {
      if (v instanceof Timestamp) {
        return v.toDate().getTime()
      }

      return mapValuesDeep(v)
    })
  }

  return value
}
