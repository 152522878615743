import { FC, PropsWithChildren } from 'react'
import { BrowserRouter, MemoryRouter } from 'react-router-dom'

export const BrowserMemoryRouter: FC<PropsWithChildren<unknown>> = (props) => {
  if (process.env.NODE_ENV === 'test') {
    return <MemoryRouter {...props} />
  }

  return (
    <BrowserRouter
      future={{
        v7_relativeSplatPath: true,
        v7_startTransition: true,
      }}
      {...props}
    />
  )
}
