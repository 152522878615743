export enum Severity {
  emergency = 0,
  alert = 1,
  critical = 2,
  error = 3,
  warning = 4,
  notice = 5,
  info = 6,
  debug = 7,
}

export type ErrorData = {
  error: {
    message: string
    severity: Severity
    code: string
  }
}

export interface ErrorWithDetails {
  details?: {
    severity?: number
  }
}

export class AppError extends Error {
  details: { severity: Severity }

  constructor(message: string, severity: Severity = Severity.error) {
    super(message)
    this.name = this.constructor.name
    this.details = { severity: severity }
  }
}
