type LogoColor = 'default' | 'white'

interface LogoProps {
  color?: LogoColor
  height?: number
}

export const LogoMobile: React.FC<LogoProps> = ({ color = 'default', height = 21 }) => {
  const colors: Record<LogoColor, string> = {
    default: '#608CA1',
    white: '#FFFFFF',
  }

  const fillColor = colors[color] || colors.default

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 375 85" fill="none">
      <path
        d="M287.815 85.0005H274.764V0.410156H338.086C357.541 0.410156 374.459 7.90244 374.459 30.2585C374.459 52.6145 357.541 60.2276 338.086 60.2276H287.815V85.0005ZM338.931 12.6153H287.815V48.0224H338.931C351.378 48.0224 361.046 43.5512 361.046 30.2585C361.046 17.0865 351.378 12.6153 338.931 12.6153Z"
        fill={fillColor}
      />
      <path
        d="M167.614 85.0005H154.562V0.410156H217.884C237.34 0.410156 254.258 7.90244 254.258 30.2585C254.258 52.6145 237.34 60.2276 217.884 60.2276H167.614V85.0005ZM218.73 12.6153H167.614V48.0224H218.73C231.177 48.0224 240.845 43.5512 240.845 30.2585C240.845 17.0865 231.177 12.6153 218.73 12.6153Z"
        fill={fillColor}
      />
      <path d="M117.322 85.0005V0.410156H130.373V85.0005H117.322Z" fill={fillColor} />
      <path
        d="M95.5937 85.0005H0.369141V72.7953L53.2985 29.4126L74.3253 12.6153H1.81926V0.410156H92.5726V12.6153L39.6432 55.9981L18.4956 72.7953H95.5937V85.0005Z"
        fill={fillColor}
      />
    </svg>
  )
}
