import { FC } from 'react'
import FullScreenSpinner from './Components/FullScreenSpinner'
import InternetCheck from './Components/InternetCheck/InternetCheck'
import { VersionCheck } from './Components/VersionCheck/VersionCheck'
import { REQUEST_STATUS } from './consts/requestStatus'
import SignupComplete from './Containers/SignUp/SignUpComplete'
import { useAuthInit, useMyUser } from './hooks'
import { LoggedInRoutes, LoggedOutRoutes } from './routes'

export const App: FC = () => {
  const isInitializing = useAuthInit((state) => state.isInitializing)
  const isLoggedIn = useAuthInit((state) => state.isLoggedIn)
  const user = useMyUser((state) => state.user)
  const isUserIncomplete = !user?.warehouseId || !user?.name

  if (isInitializing) {
    return <FullScreenSpinner requestStatus={REQUEST_STATUS.PENDING} message="Spinning up" onTryAgain={() => {}} />
  }

  if (!isLoggedIn) {
    return <LoggedOutRoutes />
  }

  if (isUserIncomplete) {
    return <SignupComplete />
  }

  return (
    <>
      <LoggedInRoutes />
      <InternetCheck />
      <VersionCheck />
    </>
  )
}
