import { FC } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { ROUTES_ZIM } from '../consts/paths'

const PORedirect: FC = () => {
  const { poNumber } = useParams()

  const urlPrefix = ROUTES_ZIM.PURCHASE_ORDER.DETAIL.replace(':poNumber', '')
  return <Navigate replace to={`${urlPrefix}${poNumber}`} />
}

export const ZimRedirectRoutes: FC = () => {
  return (
    <Routes>
      <Route path="po" element={<Navigate replace to={ROUTES_ZIM.PURCHASE_ORDER.LIST} />} />
      {/* Short url for purchase order */}
      <Route path={ROUTES_ZIM.PURCHASE_ORDER.DETAIL_SHORTURL} element={<PORedirect />} />
      {/* Temporary redirect for old purchase order page */}
      <Route path="purchase-order-v3/:poNumber" element={<PORedirect />} />
    </Routes>
  )
}
