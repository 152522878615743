/**
 * @deprecated use ROUTES_ZIPP, ROUTES_ZIM, ROUTES_CORE instead
 */
export const ROUTES = {
  PROFILE: '/profile',
  QUEUE_LISTENING: '/queue',
  ORDERS_WRONG_ITEM_SCANNED: '/orders/wrong-item-scanned',
  ORDERS_ASSIGN_BARCODE: '/orders/assign-barcode',
  WAREHOUSE_SELECTION: '/warehouse-selection/:pin',
  DASHBOARD: '/dashboard',
  STOCK_ADJUSTMENT_V2_ADJUST_STOCK: '/adjust-stock',
  STOCK_ADJUSTMENT_V2_MOVE_SKU: '/move-sku',
  STOCK_ADJUSTMENT_V2_MOVE_SKU_REPLENISHMENT: '/move-sku/:oldLocation/:newLocation/:sku',
  USERS: '/users',
  CYCLE_COUNT_V2: '/cycle-count-v2',
  CYCLE_COUNT_V2_DETAILS: '/cycle-count-v2/:location',
  CYCLE_COUNT_V3: '/cycle-count-v3',
  CYCLE_COUNT_V3_LOCATION: '/cycle-count-v3/:location',
  CYCLE_COUNT_V3_SKU: '/cycle-count-v3/:location/:sku',
  CYCLE_COUNT_V3_CONFIRM: '/cycle-count-v3/:location/:sku/confirm',
  CYCLE_COUNT_V3_DISCREPANCY: '/cycle-count-v3/:location/:sku/discrepancy',
  CYCLE_COUNT_V3_ADD_EXPIRY_DATE: '/cycle-count-v3/:location/:sku/add',
  INBOUND_V2: '/inboundV2',
  INBOUND_V2_PURCHASE_ORDER: '/inboundV2/:purchaseOrderId',
  INBOUND_V2_PRODUCT_LIST: '/inboundV2/:purchaseOrderId/products',
  INBOUND_V2_ITEM: '/inboundV2/:purchaseOrderId/products/:id',
  INBOUND_V3: '/inboundV3',
  INBOUND_V3_PURCHASE_ORDER: '/inboundV3/:purchaseOrderId',
  INBOUND_V3_SKU_SUMMARY_READ_ONLY: '/inboundV3/:purchaseOrderId/:sku/summary-read-only',
  INBOUND_V3_SKU_NOT_FOUND: '/inboundV3/:purchaseOrderId/sku-not-found/:sku?',
  INBOUND_V3_SKU: '/inboundV3/:purchaseOrderId/:sku',
  INBOUND_V3_SKU_QUANTITY: '/inboundV3/:purchaseOrderId/:sku/quantity',
  INBOUND_V3_SKU_SUMMARY: '/inboundV3/:purchaseOrderId/:sku/summary',
  INBOUND_V3_SKU_SET_ASIDE: '/inboundV3/:purchaseOrderId/:sku/set-aside',
  INBOUND_V3_SKU_ALLOCATION: '/inboundV3/:purchaseOrderId/:sku/allocation',
  PUTAWAY: '/putaway',
  PUTAWAY_LIST: '/putaway/:containerId',
  PUTAWAY_SUCCESS: '/putaway/:containerId/success',
  PUTAWAY_ITEM: '/putaway/:containerId/:stockMovementId',
  REVIEW_CAGE_PO_LIST: '/review-po-list/:cageId',
  REVIEW_CAGE_PO_SKU_LIST: '/review-po-list/:cageId/:poId',
  REVIEW_CAGE_PO_SKU_ITEM: '/review-po-list/:cageId/:poId/:skuId',
  LOCATIONS_V3: '/locations-v3',
  PICKING_V2: '/pickingV2',
  PICKING_DC_V3_AISLE_LIST: '/pickingV2/:purchaseOrderId/aisles',
  PICKING_V2_PRODUCT_LIST: '/pickingV2/:purchaseOrderId/:containerId/products',
  PICKING_DC_V3_PRODUCT_LIST: '/pickingV2/:purchaseOrderId/:containerId/:aisleId/products',
  PICKING_V2_SUCCESS: '/pickingV2/:purchaseOrderId/:containerId/success',
  PICKING_V2_SUMMARY: '/pickingV2/:purchaseOrderId/:containerId/summary',
  PICKING_V3: '/pickingV3',
  PICKING_V3_PRODUCT_LIST: '/pickingV3/:orderId/:containerId/products',
  STOCK_MOVEMENT_EXPLORER: '/stock-movement-explorer',
  STOCK_MOVEMENT_EXPLORER_DETAILS: '/stock-movement-explorer/:id',
  DISCREPANCY_SWEEPER: '/discrepancy-sweeper',
  DISCREPANCY_SWEEPER_DETAILS: '/discrepancy-sweeper/:locationId',
  DISCREPANCY_EXPLORER: '/discrepancy-explorer',
  DEBUGGING: '/debugging',
  EXPIRY_SWEEPER: '/expiry-sweeper',
  EXPIRY_SWEEPER_LOCATION: '/expiry-sweeper/:location',
  EXPIRY_SWEEPER_SKU: '/expiry-sweeper/:location/:sku',
  AUTO_INBOUND_PRODUCTS: '/auto-inbound/:purchaseOrderId/products',
  SUPPLIERS: '/suppliers',
  LOG_EXPLORER: '/log-explorer',
  STOCK_TRACKER: '/stock-tracker',
  STOCK_EXPLORER: '/stock-explorer',
}
