import { FC, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ZippLayout } from '../Components/ZippLayout/ZippLayout'
import { ROUTES_CORE, ROUTES_ZIPP } from '../consts/paths'
import { BrowserMemoryRouter } from './BrowseMemoryRouter'

const Putaway = lazy(() => import('../Containers/Putaway/Putaway'))
const PutawayList = lazy(() => import('../Containers/Putaway/PutawayList/PutawayList'))
const PutawaySuccess = lazy(() => import('../Containers/Putaway/PutawaySuccess/PutawaySuccess'))
const PutawayItem = lazy(() => import('../Containers/Putaway/PutawayItem/PutawayItem'))
const WarehouseSelect = lazy(() => import('../Containers/WarehouseSelect/WarehouseSelect'))
const InboundV2 = lazy(() => import('../Containers/InboundV2'))
const InboundV2PurchaseOrder = lazy(() => import('../Containers/InboundV2/InboundPurchaseOrder'))
const InboundV2Item = lazy(() => import('../Containers/InboundV2/InboundItem'))
const InboundV3 = lazy(() => import('../Containers/InboundV3/InboundV3'))
const InboundV3PurchaseOrder = lazy(() => import('../Containers/InboundV3/InboundPurchaseOrder/InboundPurchaseOrder'))
const InboundV3SkuContextWrapper = lazy(
  () => import('../Containers/InboundV3/InboundSkuContextWrapper/InboundSkuContextWrapper')
)
const SKUSummary = lazy(() => import('../Containers/InboundV3/SKUSummary/SKUSummary'))
const SKUNotFound = lazy(() => import('../Containers/InboundV3/SKUNotFound/SKUNotFound'))
const InboundV3SkuPreview = lazy(() => import('../Containers/InboundV3/InboundSkuPreview/InboundSkuPreview'))
const InboundV3SkuLayout = lazy(() => import('../Containers/InboundV3/InboundSkuLayout/InboundSkuLayout'))
const InboundV3Quantity = lazy(() => import('../Containers/InboundV3/InboundQuantity/InboundQuantity'))
const InboundV3SetAside = lazy(() => import('../Containers/InboundV3/InboundSetAside/InboundSetAside'))
const InboundV3Allocation = lazy(() => import('../Containers/InboundV3/InboundAllocation/InboundAllocation'))
const InboundV3Summary = lazy(() => import('../Containers/InboundV3/InboundSkuSummary/InboundSkuSummary'))
const ReviewCagePOList = lazy(() => import('../Containers/Putaway/ReviewCagePOList/ReviewCagePOList'))
const ReviewCagePOSKUList = lazy(() => import('../Containers/Putaway/ReviewCagePOSKUList/ReviewCagePOSKUList'))
const ReviewCageSKUAction = lazy(() => import('../Containers/Putaway/ReviewCageSKUAction/ReviewCageSKUAction'))
const CycleCountV2 = lazy(() => import('../Containers/CycleCountV2/CycleCount'))
const CycleCountV2Details = lazy(() => import('../Containers/CycleCountV2/CycleCountDetails'))
const CycleCountLayout = lazy(() => import('../Containers/CycleCountV3/CycleCountLayout/CycleCountLayout'))
const CycleCountV3 = lazy(() => import('../Containers/CycleCountV3/CycleCount'))
const CycleCountV3Location = lazy(() => import('../Containers/CycleCountV3/CycleCountLocation/CycleCountLocation'))
const CycleCountV3Sku = lazy(() => import('../Containers/CycleCountV3/CycleCountSku/CycleCountSku'))
const CycleCountAddExpiryDate = lazy(
  () => import('../Containers/CycleCountV3/CycleCountAddExpiryDate/CycleCountAddExpiryDate')
)
const CycleCountConfirm = lazy(() => import('../Containers/CycleCountV3/CycleCountConfirm/CycleCountConfirm'))
const CycleCountDiscrepancy = lazy(
  () => import('../Containers/CycleCountV3/CycleCountDiscrepancy/CycleCountDiscrepancy')
)
const QueueListener = lazy(() => import('../Containers/QueueListener'))
const AdjustStockV2 = lazy(() => import('../Containers/StockAdjustmentV2/AdjustStock'))
const MoveSKUV2 = lazy(() => import('../Containers/StockAdjustmentV2/MoveSKU'))
const PickingV2 = lazy(() => import('../Containers/PickingV2'))
const DCPickingV3POAisleList = lazy(() => import('../Containers/PickingV2/PickingPOAisleList'))
const PickingV2ProductList = lazy(() => import('../Containers/PickingV2/PickingProductList'))
const PickingV3ProductList = lazy(() => import('../Containers/PickingV3/PickingProductList'))
const PickingV2Success = lazy(() => import('../Containers/PickingV2/PickingSuccess'))
const PickingV2Summary = lazy(() => import('../Containers/PickingV2/PickingSummary'))
const StockMovementExplorer = lazy(() => import('../Containers/StockMovementExplorer/StockMovementExplorer'))
const ExpirySweeper = lazy(() => import('../Containers/ExpirySweeper/ExpirySweeper'))
const ExpirySweeperLocation = lazy(() => import('../Containers/ExpirySweeper/ExpirySweeperLocation'))
const ExpirySweeperSku = lazy(() => import('../Containers/ExpirySweeper/ExpirySweeperSku'))
const DiscrepancySweeper = lazy(() => import('../Containers/DiscrepancySweeper/DiscrepancySweeper'))
const DiscrepancySweeperDetails = lazy(() => import('../Containers/DiscrepancySweeper/DiscrepancySweeperDetails'))
const Debugging = lazy(() => import('../Containers/Debugging'))
const AutoInboundProducts = lazy(() => import('../Containers/AutoInbound/AutoInboundProducts/AutoInboundProducts'))

export const ZippRoutes: FC = () => {
  return (
    <BrowserMemoryRouter>
      <Routes>
        <Route path={ROUTES_CORE.INDEX} element={<ZippLayout />}>
          <Route index element={<Navigate replace to={ROUTES_ZIPP.QUEUE_LISTENING} />} />
          <Route path={ROUTES_ZIPP.QUEUE_LISTENING} element={<QueueListener />} />
          <Route path={ROUTES_ZIPP.WAREHOUSE_SELECTION} element={<WarehouseSelect />} />
          <Route path={ROUTES_ZIPP.AUTO_INBOUND_PRODUCTS} element={<AutoInboundProducts />} />
          <Route path={ROUTES_ZIPP.INBOUND_V2} element={<InboundV2 />} />
          <Route path={ROUTES_ZIPP.INBOUND_V2_PURCHASE_ORDER} element={<InboundV2PurchaseOrder />} />
          <Route path={ROUTES_ZIPP.INBOUND_V2_ITEM} element={<InboundV2Item />} />

          <Route path={ROUTES_ZIPP.INBOUND_V3} element={<InboundV3 />} />
          <Route path={ROUTES_ZIPP.INBOUND_V3_PURCHASE_ORDER} element={<InboundV3PurchaseOrder />} />
          <Route path={ROUTES_ZIPP.INBOUND_V3_SKU_SUMMARY_READ_ONLY} element={<SKUSummary />} />
          <Route path={ROUTES_ZIPP.INBOUND_V3_SKU_NOT_FOUND} element={<SKUNotFound />} />
          <Route path={ROUTES_ZIPP.INBOUND_V3_SKU} element={<InboundV3SkuContextWrapper />}>
            <Route index element={<InboundV3SkuPreview />} />
            <Route element={<InboundV3SkuLayout />}>
              <Route path="quantity" element={<InboundV3Quantity />} />
              <Route path="set-aside" element={<InboundV3SetAside />} />
              <Route path="summary" element={<InboundV3Summary />} />
              <Route path="allocation" element={<InboundV3Allocation />} />
            </Route>
          </Route>

          <Route path={ROUTES_ZIPP.PUTAWAY} element={<Putaway />} />
          <Route path={ROUTES_ZIPP.PUTAWAY_SUCCESS} element={<PutawaySuccess />} />
          <Route path={ROUTES_ZIPP.PUTAWAY_LIST} element={<PutawayList />} />
          <Route path={ROUTES_ZIPP.REVIEW_CAGE_PO_LIST} element={<ReviewCagePOList />} />
          <Route path={ROUTES_ZIPP.REVIEW_CAGE_PO_SKU_LIST} element={<ReviewCagePOSKUList />} />
          <Route path={ROUTES_ZIPP.REVIEW_CAGE_PO_SKU_ITEM} element={<ReviewCageSKUAction />} />
          <Route path={ROUTES_ZIPP.PUTAWAY_ITEM} element={<PutawayItem />} />
          <Route path={ROUTES_ZIPP.PICKING_V2} element={<PickingV2 />} />
          <Route path={ROUTES_ZIPP.PICKING_DC_V3_AISLE_LIST} element={<DCPickingV3POAisleList />} />
          <Route path={ROUTES_ZIPP.PICKING_V2_PRODUCT_LIST} element={<PickingV2ProductList />} />
          <Route path={ROUTES_ZIPP.PICKING_DC_V3_PRODUCT_LIST} element={<PickingV2ProductList />} />
          <Route path={ROUTES_ZIPP.PICKING_V2_SUMMARY} element={<PickingV2Summary />} />
          <Route path={ROUTES_ZIPP.PICKING_V2_SUCCESS} element={<PickingV2Success />} />
          <Route path={ROUTES_ZIPP.PICKING_V3_PRODUCT_LIST} element={<PickingV3ProductList />} />
          <Route path={ROUTES_ZIPP.STOCK_ADJUSTMENT_V2_ADJUST_STOCK} element={<AdjustStockV2 />} />
          <Route path={ROUTES_ZIPP.STOCK_ADJUSTMENT_V2_MOVE_SKU} element={<MoveSKUV2 />} />
          <Route path={ROUTES_ZIPP.STOCK_ADJUSTMENT_V2_MOVE_SKU_REPLENISHMENT} element={<MoveSKUV2 />} />
          <Route path={ROUTES_ZIPP.CYCLE_COUNT_V2} element={<CycleCountV2 />} />
          <Route path={ROUTES_ZIPP.CYCLE_COUNT_V2_DETAILS} element={<CycleCountV2Details />} />
          <Route path={ROUTES_ZIPP.CYCLE_COUNT_V3} element={<CycleCountV3 />} />
          <Route path={ROUTES_ZIPP.CYCLE_COUNT_V3_LOCATION} element={<CycleCountV3Location />} />
          <Route path={ROUTES_ZIPP.CYCLE_COUNT_V3_SKU} element={<CycleCountLayout />}>
            <Route index element={<CycleCountV3Sku />} />
            <Route path="discrepancy" element={<CycleCountDiscrepancy />} />
            <Route path="confirm" element={<CycleCountConfirm />} />
            <Route path="add" element={<CycleCountAddExpiryDate />} />
          </Route>
          <Route path={ROUTES_ZIPP.STOCK_MOVEMENT_EXPLORER} element={<StockMovementExplorer />} />
          <Route path={ROUTES_ZIPP.STOCK_MOVEMENT_EXPLORER_DETAILS} element={<StockMovementExplorer />} />
          <Route path={ROUTES_ZIPP.DISCREPANCY_SWEEPER} element={<DiscrepancySweeper />} />
          <Route path={ROUTES_ZIPP.DISCREPANCY_SWEEPER_DETAILS} element={<DiscrepancySweeperDetails />} />
          <Route path={ROUTES_ZIPP.EXPIRY_SWEEPER} element={<ExpirySweeper />} />
          <Route path={ROUTES_ZIPP.EXPIRY_SWEEPER_LOCATION} element={<ExpirySweeperLocation />} />
          <Route path={ROUTES_ZIPP.EXPIRY_SWEEPER_SKU} element={<ExpirySweeperSku />} />
          <Route path={ROUTES_ZIPP.DEBUGGING} element={<Debugging />} />
        </Route>

        <Route path="*" element={<Navigate replace to={ROUTES_CORE.INDEX} />} />
      </Routes>
    </BrowserMemoryRouter>
  )
}
