import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ROUTES_CORE } from '../consts/paths'
import Login from '../Containers/Login/Login'
import { BrowserMemoryRouter } from './BrowseMemoryRouter'

export const LoggedOutRoutes: FC = () => {
  return (
    <BrowserMemoryRouter>
      <Routes>
        <Route path={ROUTES_CORE.LOGIN} element={<Login />} />
        <Route path="*" element={<Navigate replace to={ROUTES_CORE.LOGIN} />} />
      </Routes>
    </BrowserMemoryRouter>
  )
}
