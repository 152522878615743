type LogoColor = 'default' | 'white'

interface LogoProps {
  color?: LogoColor
  height?: number
}

export const Logo: React.FC<LogoProps> = ({ color = 'default', height = 17 }) => {
  const colors: Record<LogoColor, string> = {
    default: '#608CA1',
    white: '#FFFFFF',
  }

  const fillColor = colors[color] || colors.default

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 277 85" fill="none">
      <path
        d="M219.455 69.7742H211.359L167.251 17.8116L167.614 30.2585V85.0005H154.562V0.410156H167.493L215.468 56.9648L263.322 0.410156H276.373V85.0005H263.322V30.2585L263.563 17.8116L219.455 69.7742Z"
        fill={fillColor}
      />
      <path d="M117.322 85.0005V0.410156H130.373V85.0005H117.322Z" fill={fillColor} />
      <path
        d="M95.5937 85.0005H0.369141V72.7953L53.2985 29.4126L74.3253 12.6153H1.81926V0.410156H92.5726V12.6153L39.6432 55.9981L18.4956 72.7953H95.5937V85.0005Z"
        fill={fillColor}
      />
    </svg>
  )
}
