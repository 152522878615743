import { Alert, Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Logo } from '../../Components/Svg/Logo'
import { LogoMobile } from '../../Components/Svg/LogoMobile'
import { useLogin } from '../../firebase/useLogin'
import { GoogleLoginBtn } from './GoogleLoginBtn'

// TODO: migrate to emotion and styled
const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(24),
    gap: '120px',
    [theme.breakpoints.down(801)]: {
      backgroundColor: '#fff',
      paddingTop: theme.spacing(16),
    },
  },
  loginContent: {
    background: '#ffffff',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '320px',
    width: '100%',
    justifyContent: 'space-between',
    backdropFilter: 'blur(10px)',
    gap: '24px',
    [theme.breakpoints.down(801)]: {
      // in the mobile app, this page is not using 100% width and heigth, so there is white background on corners
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      maxWidth: '280px',
      padding: theme.spacing(2),
      gap: '16px',
    },
  },
  select: {
    border: '1px solid #c4c4c4',
    padding: '11px',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    borderRadius: theme.spacing(1),
  },
  divider: {
    width: '100%',
  },
  dividerText: {
    background: 'white',
    padding: '0 4px',
    marginTop: '-12px',
    color: 'grey',
  },
}))

const Login = () => {
  const { classes } = useStyles()
  const { isSmallScreen, isTouchDevice, loginState, errorState, logoutIframeRef, handleLoginWithSSO } = useLogin()

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="center">
        {isSmallScreen ? <LogoMobile height={40} /> : <Logo color="white" height={60} />}
      </Box>

      <Box className={classes.loginContent}>
        <Typography variant="body2" sx={{ fontWeight: '900', alignSelf: 'center' }}>
          Login with your Zapp Account
        </Typography>

        {/* Errors */}
        {errorState && errorState !== 'submitting' && <Alert severity="error">{errorState}</Alert>}

        <Box component="div" display="flex" flexDirection="column">
          <GoogleLoginBtn loading={loginState === 'submitting-sso'} onClick={handleLoginWithSSO} />
          {/* Logout Google with an iframe for Zipp only */}
          {isSmallScreen && isTouchDevice && (
            <iframe
              ref={logoutIframeRef}
              width={0}
              height={0}
              referrerPolicy="no-referrer"
              style={{ visibility: 'hidden' }}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Login
