import { useMediaQuery } from '@mui/material'
import { FC } from 'react'
import { ZimRoutes } from './ZimRoutes'
import { ZippRoutes } from './ZippRoutes'

export const LoggedInRoutes: FC = () => {
  const isZIPP = useMediaQuery('(max-width:800px)', { noSsr: true })

  if (isZIPP) {
    return <ZippRoutes />
  }

  return <ZimRoutes />
}
