import { Alert, Box, Button, Card, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { signOut } from 'firebase/auth'
import { Component, ErrorInfo, ReactNode, Suspense } from 'react'
import { LoadingState } from './Components/Page/LoadingState'
import { ROUTES_CORE } from './consts/paths'
import { zimBackendAuth } from './firebase'
import { LogAction, LogCategory, log, logSentryError } from './utils/logHelper'

const INITIAL_STATE = {
  hasError: false,
  message: '',
  stack: '',
}
const styles = () => ({
  card: {
    width: '600px',
    padding: '30px',
    background: '#fdfafa',
  },
  inActiveUser: {
    marginTop: '40vh',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

interface Props {
  children: ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  classes: any
}

interface State {
  hasError: boolean
  message: string
  stack: string
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = INITIAL_STATE

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      message: error?.message ?? '',
      stack: error?.stack ?? '',
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logSentryError(error)
    log(LogCategory.UNCAUGHT_ERROR, LogAction.UNCAUGHT_ERROR, error.message, {
      error,
      errorInfo,
      url: window.location.href,
    })
  }

  refresh = () => {
    this.setState(INITIAL_STATE)
    window.location.reload()
  }

  logout = async () => {
    await signOut(zimBackendAuth)
    window.location.href = ROUTES_CORE.LOGIN
  }

  public render() {
    const { classes } = this.props
    if (this.state.hasError) {
      return (
        <div className={classes.inActiveUser}>
          <Card className={classes.card}>
            <Alert severity="error">
              <Typography>Something went wrong, please try again later.</Typography>

              <Box mt={1} component="details">
                <summary>Error: {this.state.message}</summary>
                <Typography variant="caption">{this.state.stack}</Typography>
              </Box>
            </Alert>

            <Box mt={2} display="flex" justifyContent="center">
              <Button onClick={this.refresh} color="primary">
                Refresh
              </Button>
              <Button onClick={this.logout} color="secondary">
                Logout
              </Button>
            </Box>
          </Card>
        </div>
      )
    }

    return <Suspense fallback={<LoadingState />}>{this.props.children}</Suspense>
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default withStyles(styles as any)(ErrorBoundary)
