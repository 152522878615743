import { Box } from '@mui/material'
import { LoadingSpinner } from '../LoadingSpinner'
import Page from './Page'
import { ReloadButton } from './ReloadButton'

export const LoadingState = () => {
  return (
    <Page header={<Box>Loading...</Box>} footer={<ReloadButton />} showFooter={true}>
      <Box display="flex" alignItems="center" justifyContent="center" height="50vh">
        <LoadingSpinner />
      </Box>
    </Page>
  )
}
