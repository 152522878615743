import { captureException } from '@sentry/react'
import { APP_VERSION } from '../../consts/version'
import { Severity } from '../../errors'
import { useMyDevice, useMyUser } from '../../hooks'
import { Flows } from './_types/SentryFlow'
import { isErrorWithSeverity } from './utils'

// both frontend and backend errors are logged with severity or 3 or lower
const shouldSkipSentryLog = (err: unknown, { severity }: ErrorMetadata): boolean => {
  // severity passed from metadata
  if (severity) {
    return severity > Severity.error
  }
  // severity passed from error
  return isErrorWithSeverity(err) && err.details.severity > Severity.error
}

interface ErrorMetadata {
  flow?: Flows
  feature?: string
  severity?: number
  [key: string]: unknown
}

export const logSentryError = (error: Error | unknown, errorMetadata: ErrorMetadata = {}) => {
  if (shouldSkipSentryLog(error, errorMetadata)) return
  const { flow = null, feature = null, ...extra } = errorMetadata
  // if (process.env.REACT_APP_FIREBASE_CONFIG !== 'production') {
  //     return console.warn(error) // eslint-disable-line no-console
  // }

  // eslint-disable-next-line no-console
  console.warn(error)

  if (process.env.NODE_ENV === 'development') return

  const newError = error instanceof Error ? error : new Error(JSON.stringify(error))

  if (flow && feature) {
    newError.name = `${flow} - ${feature}`
  } else if (flow) {
    newError.name = flow
  }

  try {
    const user = useMyUser.getState().user
    const device = useMyDevice.getState().device
    const data = { ...user, deviceId: device?.id }
    captureException(newError, {
      user: data,
      tags: { appVersion: APP_VERSION, flow, feature },
      extra: extra,
    })
  } catch {}
}
