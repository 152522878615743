import { useEffect, useRef, useState } from 'react'

interface Props {
  onHover?: () => void
  onUnhover?: () => void
}

export const useHover = ({ onHover, onUnhover }: Props = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null)
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseOver = () => {
    setIsHovered(true)
    onHover?.()
  }

  const handleMouseOut = () => {
    setIsHovered(false)
    onUnhover?.()
  }

  useEffect(() => {
    if (!ref.current) return

    ref.current.addEventListener('mouseover', handleMouseOver)
    ref.current.addEventListener('mouseout', handleMouseOut)

    return () => {
      if (!ref.current) return
      ref.current.removeEventListener('mouseover', handleMouseOver)
      ref.current.removeEventListener('mouseout', handleMouseOver)
    }
  }, [ref.current])

  return [ref, isHovered]
}
