import { FC, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ROUTES_CORE, ROUTES_ZIM } from '../consts/paths'
import { BrowserMemoryRouter } from './BrowseMemoryRouter'
import { ZimRedirectRoutes } from './ZimRedirectRoutes'

const DashboardPage = lazy(() => import('../Containers/Dashboard/Dashboard'))
const DiscrepancyExplorer = lazy(() => import('../Containers/DiscrepancyExplorer'))
const LocationsV3 = lazy(() => import('../Containers/LocationsV3'))
const StockTracker = lazy(() => import('../Containers/StockTracker'))
const LogExplorer = lazy(() => import('../Containers/LogExplorer'))
const PurchaseOrdersV2 = lazy(() => import('../Containers/PurchaseOrders/PurchaseOrdersV2/PurchaseOrdersV2'))
const PurchaseOrderDetailV3 = lazy(
  () => import('../Containers/PurchaseOrders/PurchaseOrderDetailV3/PurchaseOrderDetailV3')
)
const StockExplorer = lazy(() => import('../Containers/StockExplorer'))
const Suppliers = lazy(() => import('../Containers/Suppliers'))
const Users = lazy(() => import('../Containers/Users'))
const Profile = lazy(() => import('../Containers/Profile/Profile'))

export const ZimRoutes: FC = () => {
  return (
    <BrowserMemoryRouter>
      <Routes>
        <Route path={ROUTES_ZIM.PROFILE} element={<Profile />} />

        <Route path={ROUTES_CORE.INDEX} element={<DashboardPage />}>
          <Route index element={<Navigate replace to={ROUTES_ZIM.PURCHASE_ORDER.LIST} />} />
          <Route path={ROUTES_ZIM.PURCHASE_ORDER.LIST} element={<PurchaseOrdersV2 />} />
          <Route path={ROUTES_ZIM.PURCHASE_ORDER.DETAIL} element={<PurchaseOrderDetailV3 />} />
          <Route path={ROUTES_ZIM.LOG_EXPLORER} element={<LogExplorer />} />
          <Route path={ROUTES_ZIM.STOCK_TRACKER} element={<StockTracker />} />
          <Route path={ROUTES_ZIM.STOCK_EXPLORER} element={<StockExplorer />} />
          <Route path={ROUTES_ZIM.DISCREPANCY_EXPLORER} element={<DiscrepancyExplorer />} />
          <Route path={ROUTES_ZIM.LOCATIONS_V3} element={<LocationsV3 />} />
          <Route path={ROUTES_ZIM.SUPPLIERS} element={<Suppliers />} />
          <Route path={ROUTES_ZIM.USERS} element={<Users />} />
        </Route>

        <Route path="*" element={<Navigate replace to={ROUTES_CORE.INDEX} />} />
      </Routes>

      <ZimRedirectRoutes />
    </BrowserMemoryRouter>
  )
}
