import { Box, Tooltip } from '@mui/material'

export function EnvironmentBanner({ height = '6px' }: { height?: string }): JSX.Element | null {
  const appEnvironment = process.env.REACT_APP_FIREBASE_CONFIG || 'development'
  const devEnvironments = ['staging', 'development', 'localhost']
  const isDevEnv = devEnvironments.includes(appEnvironment)

  if (!isDevEnv) {
    return null
  }

  const bgColor = appEnvironment === 'staging' ? '#fc0' : '#0fc'
  const bgGradient = `repeating-linear-gradient(45deg, ${bgColor}, ${bgColor} 10px, #333 10px, #333 20px)`

  return (
    <Tooltip title={appEnvironment.toUpperCase()}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          width: '100%',
          height: height,
          zIndex: 100,
          opacity: 0.8,
          background: bgGradient,
        }}
      />
    </Tooltip>
  )
}
