import { Stack } from '@mui/material'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { EnvironmentBanner } from '../EnvironmentBanner/indext'
import { Tabbar } from '../Tabbar/Tabbar'

export const ZippLayout: FC = () => {
  return (
    <Stack height="100%">
      <Stack id="scroll-parent" flexGrow={1}>
        <EnvironmentBanner height="4px" />
        <Outlet />
        <Tabbar />
      </Stack>
    </Stack>
  )
}
