import { useMyUser } from '../../hooks'
import { RoleBasedFeatureFlagValue } from './types'
import useFeatureFlag from './useFeatureFlag'

export interface UserRoleFeatureFlags {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any
  warehouseId?: string
  userRole?: string
}

export type RoleBasedFeatureFlags = 'stockAdjustmentLimitsV2'

const useUserRoleFeatureFlag = (flags: RoleBasedFeatureFlags[]): UserRoleFeatureFlags => {
  const warehouseId = useMyUser((state) => state.user?.warehouseId)
  const userRole = useMyUser((state) => state.user?.role)
  const { values, config } = useFeatureFlag(flags, warehouseId)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const outValues: any = {}
  for (const key in values) {
    const roleBasedValue = values[key] as RoleBasedFeatureFlagValue
    outValues[key] = !!userRole && userRole in roleBasedValue ? roleBasedValue[userRole] : (roleBasedValue.all ?? false)
  }

  return {
    values: outValues,
    config,
    warehouseId,
    userRole,
  } as UserRoleFeatureFlags
}

export default useUserRoleFeatureFlag
